<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
            >
            <b-row>
                <b-col class="text-left">
                    <b-input-group>
                        <b-form-input
                            id="v-search"
                            type="text"
                            :placeholder="$t('Search')"
                            v-model="searchText"
                            size="sm"
                        />
                        <b-input-group-append is-button>
                            <b-button
                                size="sm"
                                variant="success"
                                class="mb-1"
                                @click="search()"
                            >
                                <feather-icon icon="SearchIcon" />
                                {{$t('Search')}}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col class="text-right">
                    <b-button
                        to="/products/create"
                        size="sm"
                        variant="success"
                        class="mb-1"
                    >
                        <feather-icon icon="PlusSquareIcon" />
                        {{$t('Create')}}
                    </b-button>
                    <!-- <b-button
                        size="sm"
                        variant="success"
                        class="mb-1"
                        @click="$bvModal.show('importAndUpdate')"
                    >
                        <feather-icon icon="PlusSquareIcon" />
                        {{$t('Import & Update')}}
                    </b-button> -->
                    <!-- <b-button
                        size="sm"
                        variant="success"
                        class="mb-1"
                        @click="exportProducts"
                    >
                        <feather-icon icon="PlusSquareIcon" />
                        {{$t('Export')}}
                    </b-button> -->
                </b-col>
            </b-row>
            <b-row class="match-height" v-if="!isLoading">
                <template v-if="Object.keys(products.data).length > 0">
                <template v-for="(product,index) in products.data">
                    <transition
                    :name="$store.state.appConfig.layout.routerTransition"
                    mode="out-in" :key="index"
                    >
                    <b-col md="12">
                        <b-link :to="`/products/`+product.id+`/edit/product-information`">
                            <b-card
                                no-body
                            >
                            <b-row>
                                <b-col cols="6" md="9">
                                    <div class="p-2">
                                        <b-card-title>
                                            {{product.name}}
                                        </b-card-title>
                                        <b-card-sub-title class="mb-1">
                                            {{product.product_code}}
                                            <b-badge
                                                :variant="product.availability == 1 ? 'success' : 'danger'"
                                                class="ml-1 mr-1 badge-glow">
                                                {{product.availability == 1 ? $t("Availabe") : $t("Not Availabe")}}
                                            </b-badge>
                                        </b-card-sub-title>
                                        <b-card-text>
                                            <div>
                                                <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                                    <div>
                                                        <h6 class="text-muted font-weight-bolder">
                                                        {{$t("Quantity")}}
                                                        </h6>
                                                        <h3 class="mb-0">
                                                        {{product.quantity}}
                                                        </h3>
                                                    </div>
                                                    <div>
                                                        <h6 class="text-muted font-weight-bolder">
                                                        {{$t("Original Price")}} ({{$t("SAR")}})
                                                        </h6>
                                                        <h3 class="mb-0">
                                                        {{product.original_price}}
                                                        </h3>
                                                    </div>
                                                    <div>
                                                        <h6 class="text-muted font-weight-bolder">
                                                        {{$t("Price After Discount")}} ({{$t("SAR")}})
                                                        </h6>
                                                        <h3 class="mb-0">
                                                        {{product.price_after_discount || "-"}}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </div>
                                </b-col>
                                <b-col cols="6" md="3" align-self="center">
                                    <b-img :src="product.media[0] ? product.media[0].url : ''" fluid />
                                </b-col>
                            </b-row>
                            </b-card>
                        </b-link>
                    </b-col>
                    </transition>
                </template>
                </template>
                <template v-else>
                <b-col>
                    <b-card class="text-center">
                        <span>{{$t('content not found')}}.</span>
                    </b-card>
                </b-col>
                </template>
            </b-row>
            <pagination align="center" :limit="7" :data="products" @pagination-change-page="getResults"></pagination>
        </b-overlay>
    </div>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
    mounted() {
        this.getResults()
    },
    data() {
        return {
            products:{},
            isLoading: true,
            searchText: ''
        }
    },
    methods: {
        getResults(page = 1) {
            useJwt.get('/products/get-all-with-pagination',{params: {
                page: page,
            }})
            .then((response) => {
                // console.log(response.data)
                this.products = response.data.data
                this.isLoading = false
            })
            .catch(response => {
                this.isLoading = false
                // console.log(response);
            });
        },
        search() {
            this.isLoading = true
            // console.log(this.searchText)
            useJwt.post('/products/search',{search: this.searchText})
            .then((response) => {
                // console.log(response.data)
                this.products = response.data.data
                this.isLoading = false
            })
            .catch(response => {
                this.isLoading = false
                // console.log(response);
            });
        },
        search() {
            this.isLoading = true
            // console.log(this.searchText)
            useJwt.post('/products/search',{search: this.searchText})
            .then((response) => {
                // console.log(response.data)
                this.products = response.data.data
                this.isLoading = false
            })
            .catch(response => {
                this.isLoading = false
                // console.log(response);
            });
        }
    }
};
</script>
<style scoped>

</style>
